import { Tooltip } from 'antd';
import { PitRouter } from 'constants/routers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetAffiliate } from 'hooks/affiliate/useGetAffiliate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import HomepageTemplate from 'template/Homepage';
import { ReactComponent as AffiliatedIcon } from 'assets/vectors/logo-navbar-affiliate.svg';
import gradient from 'assets/images/withdraw/gradient-withdraw.png';

import {
  AffiliateContent,
  AffiliateContentContainer,
  AffiliateContentItem,
  AffiliateContentItemTitle,
  AffiliateContentItemValue,
  AffiliateContentTitle,
  AffiliateContentWrapper,
  AffiliateIconWrapper,
  AffiliateWrapper,
  CopyUrlContainer,
  GradientBottom,
  LeftTitle,
  PageTitle,
  RightContentContainer,
  UnderlineText,
  UrlWrapper,
} from './styled';

export const Affiliate = () => {
  const { affiliateInfo, loading } = useGetAffiliate();

  return (
    <HomepageTemplate>
      <AffiliateWrapper>
        <AffiliateContentWrapper>
          <AffiliateContentTitle>
            <AffiliateIconWrapper>
              <AffiliatedIcon />
            </AffiliateIconWrapper>
            <PageTitle>Affiliate Statistics</PageTitle>
          </AffiliateContentTitle>

          <RightContentContainer>
            <AffiliateContentContainer>
              <LeftTitle>Total Statistics</LeftTitle>
              <AffiliateContent>
                <AffiliateContentItem>
                  <AffiliateContentItemTitle>
                    Total unique players
                  </AffiliateContentItemTitle>
                  <AffiliateContentItemValue>
                    {affiliateInfo?.uniquePlayers
                      ?.toLocaleString()
                      .replace(/,/g, ' ') || 0}
                  </AffiliateContentItemValue>
                </AffiliateContentItem>
                <AffiliateContentItem>
                  <AffiliateContentItemTitle>
                    Total attempts
                  </AffiliateContentItemTitle>
                  <AffiliateContentItemValue>
                    {affiliateInfo?.totalAttempts
                      ?.toLocaleString()
                      .replace(/,/g, ' ') || 0}
                  </AffiliateContentItemValue>
                </AffiliateContentItem>
                <AffiliateContentItem>
                  <AffiliateContentItemTitle>
                    Total revenue
                  </AffiliateContentItemTitle>
                  <AffiliateContentItemValue className="biga-value">
                    {affiliateInfo?.totalEarnedAmount?.amount ? (
                      <>
                        {parseFloat(
                          formatUnits(
                            affiliateInfo.totalEarnedAmount.amount,
                            affiliateInfo.totalEarnedAmount.decimals || 18,
                          ),
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        {affiliateInfo.totalEarnedAmount.symbol}
                        {' ($'}
                        {(
                          parseFloat(
                            formatUnits(
                              affiliateInfo.totalEarnedAmount.amount,
                              affiliateInfo.totalEarnedAmount.decimals || 18,
                            ),
                          ) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        {')'}
                      </>
                    ) : (
                      0
                    )}
                  </AffiliateContentItemValue>
                </AffiliateContentItem>
              </AffiliateContent>
            </AffiliateContentContainer>

            <AffiliateContentContainer>
              <LeftTitle>Affiliate Link</LeftTitle>
              <AffiliateContent>
                <CopyUrlContainer>
                  <UrlWrapper>
                    {location.origin +
                      PitRouter.REGISTER +
                      '/' +
                      affiliateInfo?.affiliateCode}
                  </UrlWrapper>
                  <CopyToClipboard
                    onCopy={() => {
                      toast.success('COPY successfully');
                    }}
                    text={
                      location.origin +
                      PitRouter.REGISTER +
                      '/' +
                      affiliateInfo?.affiliateCode
                    }
                  >
                    <Tooltip title="Copy">
                      <UnderlineText>Copy</UnderlineText>
                    </Tooltip>
                  </CopyToClipboard>
                </CopyUrlContainer>
              </AffiliateContent>
            </AffiliateContentContainer>
          </RightContentContainer>
        </AffiliateContentWrapper>
        <GradientBottom src={gradient} />
      </AffiliateWrapper>
    </HomepageTemplate>
  );
};

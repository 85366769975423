import {
  HangOutLetTalkButtonWrapper,
  HangOutLetTalkCharacter,
  HangOutLetTalkDesciption,
  HangOutLetTalkTitle,
  HangOutLetTalkWrapper,
  HangOutSocial,
  HangOutSocialSubTitle,
  HangOutSocialTitle,
  HangOutSocialWrapper,
  HangOutSocials,
  WhitepaperText,
  Wrapper,
} from './styled';
import { ReactComponent as Discord } from 'assets/icons/discord-no-fill.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram-no-fill.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter-no-fill.svg';
import letTalk from 'assets/main/hangout/let-talk.png';
import { MainButton } from 'components/Button/MainButton';
import { Link } from 'react-router-dom';

export const HangOut = () => {
  const whitepaperLink = `${process.env.REACT_APP_HOST}/whitepaper.pdf`;
  return (
    <Wrapper>
      <HangOutSocialWrapper>
        <HangOutSocialTitle>
          <span>HANG OUT,</span> BRO!
        </HangOutSocialTitle>
        <HangOutSocialSubTitle>
          {"Don't miss anything! Join our social media."}
        </HangOutSocialSubTitle>
        <HangOutSocials>
          <Link to="https://twitter.com/bigaarcade" target="_blank">
            <Twitter className="twitter" />
          </Link>
          <Link to="https://www.instagram.com/bigaarcade/" target="_blank">
            <Instagram className="instagram" />
          </Link>
          <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
            <Discord className="discord" />
          </Link>
        </HangOutSocials>
      </HangOutSocialWrapper>
      {/* <WhitepaperText>
        Review Our{' '}
        <span
          onClick={() => {
            window.open(whitepaperLink, '_blank');
          }}
        >
          Whitepaper
        </span>
      </WhitepaperText> */}
      <HangOutLetTalkWrapper>
        <HangOutLetTalkTitle>ARE YOU A GAME DEVELOPER?</HangOutLetTalkTitle>
        <HangOutLetTalkDesciption>
          BIGA can help you monetize your existing or brand new game instantly,
          without clunky advertising or big upfront expenses.
        </HangOutLetTalkDesciption>
        <HangOutLetTalkButtonWrapper id="XbXfic2M">
          <MainButton width="200px" onClick={() => console.log('')}>
            {"Let's talk"}
          </MainButton>
          <HangOutLetTalkCharacter src={letTalk} alt="" />
        </HangOutLetTalkButtonWrapper>
      </HangOutLetTalkWrapper>
    </Wrapper>
  );
};
